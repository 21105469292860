import React from "react"
import { graphql } from "gatsby"
import styled from "styled-components"
import ScrollToTop from "react-scroll-up"

import Layout from "@components/Layout"
import SEO from "@components/SEO"
import backgroundImage from "../../../static/images/home.jpg"
import HeaderSlider from "@components/HeaderSlider"
import ContentWrapper from "@sections/ContentWrapper"
import ContentCard from "@components/ContentCard"
import ContactCard from "@components/ContactCard"
import Copyright from "@components/Copyright"
import { LogoStathis } from "@components/Logos"
import Video from "@components/Video"
import SocialIcon from "@components/SocialIcon"

const artistFirstName = "Stathis"
const artistLastName = "Karapanos"

const artistName = `${artistFirstName} ${artistLastName}`
const sliderOptions = {
  autoplay: "2000",
  previousButton: "",
  nextButton: "",
  // touchDisabled: "true",
  duration: "1500",
}

export default function artistPage({ data }) {
  const slides = data.allFile.edges

  const scrollButtonStyle = {
    position: 'fixed',
    bottom: '2rem',
    right: '8%',
    cursor: 'pointer',
    transitionDuration: '0.2s',
    transitionTimingFunction: 'linear',
    transitionDelay: '0s',
    zIndex: '100'
  }

  const socialLinks = {
    color: 'rgba(51, 51, 51, 0.6)',
    size: '16'
  }

  return (
    <Layout pageClass="artist-page artist-page-wip">
      <ScrollToTop
        showUnder={300}
        style={scrollButtonStyle}
        >
        <Icon>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            height="60px"
            width="40px"
            fill="rgba(51, 122, 183, 0.75)"
            data-name="Layer 1"
            viewBox="0 0 100 100"
            x="0px"
            y="0px"
          >
            <title>Scroll To Top</title>
            <polygon points="48.3 18.03 48.3 47.44 51.7 47.44 51.7 18.03 64.78 31.1 67.19 28.69 50 11.5 32.81 28.69 35.22 31.1 48.3 18.03" />
            <polygon points="51.7 54.26 50.71 53.27 50 52.56 49.29 53.27 48.3 54.26 47.59 54.97 32.81 69.75 35.22 72.16 48.3 59.08 48.3 88.5 51.7 88.5 51.7 59.08 64.78 72.16 67.19 69.75 52.41 54.97 51.7 54.26" />
          </svg>
        </Icon>
      </ScrollToTop>
      <SEO
        title={artistName}
        template="%s · Flutist"
        description={`${artistName}'s official Artist Page`}
        image={slides.[0].node.childImageSharp.fluid.src}
        article
      />
      <Background />
      <ContentWrapper>
        <HeaderSlider
          artistName={artistName}
          slides={slides}
          sliderOptions={sliderOptions}
        />
        <ContentCard>
          <LogoStathis width="auto" height="150" styles="max-width: 50%; height: auto;"/>
          <ContactCard
            contactText="Contact"
            contactDetail="terzis@alfaconsulting.services"
          />
          <VideoWrapper className="row space-before">
            <Video className="col-md-6" videoSrcURL="https://www.youtube.com/embed/M2E7KUFI9eQ?modestbranding=1" videoTitle="Bach - Badinerie"/>
            <Video className="col-md-6" videoSrcURL="https://www.youtube.com/embed/d7QN6AKE_Lg?modestbranding=1" videoTitle="Bernstein Award Winning Recital at the Schleswig Holstein Music Festival"/>
          </VideoWrapper>
          <h3>HR Frankfurt Radio Symphony</h3>
          <VideoWrapper className="row">
            <Video className="col-md-6" videoSrcURL="https://www.youtube.com/embed/ULuTlvDQevA?modestbranding=1" videoTitle="C. Saint-Saëns- La Muse et le Poète"/>
            <Video className="col-md-6" videoSrcURL="https://www.youtube.com/embed/AwvKYptSrDE?modestbranding=1" videoTitle="F. Martin- Ballade"/>
          </VideoWrapper>
          <h1>Biography</h1>
          <p>
            I was born in Athens, Greece in September of 1996, son to a ballet
            dancer, a banker and future brother to a then unborn sister, who
            today studies law. At the age of 5, I declared my love for the flute
            and that I would one day become a soloist. After a long year of
            trying to persuade both of my parents, I enrolled at the National
            Conservatory in Athens and received my first lessons from Ms. Myrto
            Tsakiri.
          </p>
          <p>
            After having moved to Bulgaria as a family, at the age of 13 I was
            accepted at the National Music School of Sofia “NMU Lyubomir
            Pipkov”, to study under Prof. Georgi Spassov. In 2011, after
            participating in Prof. Robert Winn’s masterclass, I was lucky enough
            to begin working with him, on an intermittent basis. In 2012 ,thanks
            to his referral, I met Prof. Greiss-Armin and her assistant Matthias
            Allin at their annual masterclass in Thomashoff, a meeting that
            marked the next stage of my musical development.
          </p>
          <p>
            In June of 2013, at the age of 16, I was accepted to study with them
            at the Music University of Karlsruhe and graduated in July of 2018
            with distinctions. In February 2016, I was selected to be the only
            student representative in the committee that would select the new
            flute professor in our university, Prof. Pirmin Grehl.
          </p>
          <p>
            During my studies, I regularly premiered different pieces from the
            composition class of Prof. W. Rihm and was often invited to perform
            as a member of both youth and professional orchestras like the State
            Theater of Karlsruhe, Kölner Kammerorchester, Orchestre
            Philharmonique de Radio France between others.
          </p>
          <p>
            Searching for inspiration is a never-ending and vitally crucial
            journey in one's life. In my case some of the most inspiring figures
            have been Maestro Zubin Mehta whom I saw for the first time on stage
            in 2005 conducting "Carmen", Maestro Christoph Eschenbach whose
            recordings I grew up with, Maestro Yutaka Sado conducting one of the
            most impressive performances of the "Requiem" by G. Verdi. I
            consider myself extraordinarily lucky and privileged to have had the
            opportunity to learn from and be invited to collaborate with them as
            a soloist, chamber musician and orchestral musician. As a chamber
            musician I have collaborated with artists like Vilde Frang, Matthias
            Goerne, Nicolas Altstaedt, Daniel Hope, Lang Lang and Marisol
            Montalvo between others and have had teacher and mentors like
            Mathieu Dufour, Vangelis Papathanassiou, Petra Müllejans, Henrick
            Wiese, Peter Lukas Graf..
          </p>
          <p>
            As a soloist I have been honored with the "Bernstein Award" from the
            Schleswig Holstein Music Festival (2020), the "Lotto Prize" from the
            Rheingau Festival (2019) and have been awarded with numerous prizes
            from International Competitions. Past and upcoming performances and
            recordings include : the World Premiere of the "Zorbas Suite" by M.
            Theodorakis and A. Wastor which I commissioned together with the
            Young Euro Classic Festival, the NCPA Orchestra (Beijing),
            Schleswig-Holstein Festival Orchestra (Hamburg, Germany), Athens
            State Symphony Orchestra (Greece), HR Symphony Orchestra
            (Frankfurt), Bamberg Symphony Orchestra (Bamberg), NHK Symphony
            Orchestra (Tokyo), Munich Symphony Orchestra (Munich) and many
            others.
          </p>
          <p>
            My ultimate dream is to understand and serve music to its fullest
            extent, which has led me to explore all possible variations of my
            instrument like the Shakuhachi and the Traverse flute to the
            Contrabass flute. Keen on musical experimentation, I have often
            joined different bands, groups and world renowned artists on stage
            like Richard Smith, Tico Pierhagen, Vassilis Rakopoulos with whom we
            meander through musical forms ranging from free jazz to hard rock.
          </p>
          <p>
            In 2016 I assisted in organizing the 3rd edition of the “Santorini
            Arts Factory” festival and the following year I was appointed “Music
            Director”. Shortly after, I was appointed First Principal Flute of
            the Athens State Symphony Orchestra, a post that I left in July of
            2018 to continue my studies with Prof. Bernold at the Conservatoire
            National Superieur in Paris and to further pursue my dreams, goals
            and career.
          </p>
          <SocialLinks>
            <SocialIcon name="linkedin" fill={socialLinks.color} size={socialLinks.size} link="https://www.linkedin.com/in/stathis-karapanos-622a90a5/" />
            <SocialIcon name="facebook" fill={socialLinks.color} size={socialLinks.size} link="https://www.facebook.com/stathis.karapanoos" />
            <SocialIcon name="instagram" fill={socialLinks.color} size={socialLinks.size} link="https://instagram.com/stathis_karapanos" />
            <SocialIcon name="youtube" fill={socialLinks.color} size={socialLinks.size} link="https://www.youtube.com/c/StathisKarapanos" />
          </SocialLinks>
        </ContentCard>
        <Copyright />
      </ContentWrapper>
    </Layout>
  )
}

const relativePath = `artists/${artistFirstName.toLowerCase()}`

export const query = graphql`
  query ImagesStathisWIP3($relativePath: String) {
    allFile(filter: { relativeDirectory: { glob: $relativePath } }, sort: {fields: name, order: ASC}) {
      edges {
        node {
          id
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`

const Background = styled.div`
position: fixed;
width: 100vw;
height: 100vh;
z-index: -100;

background-image: linear-gradient(
  to bottom,
  rgba(255, 255, 255, 0.84) 0%,
  rgba(199, 199, 199, 0.51) 75%),
  url(${backgroundImage});
background-position: center;
background-size: cover;
`

const VideoWrapper = styled.div`
margin-bottom: 2.4em;
margin-top: 1,2em;

&.space-before {
  margin-top: 2.4em;
}

& iframe {
  width: 100%;
  min-height: 250px;
}
`

const Icon = styled.div`
  border-radius: 2px;
  background-color: rgba(236, 244, 249, 0.4);
  backdrop-filter: blur(8px);
  line-height: 0;
`

const SocialLinks = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
`
